import { cn, InfoPopover, Pill, PillProps } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

export const BetaPill = ({ className, ...delegated }: PillProps) => {
  const { t } = useTranslation();

  return (
    <Pill
      size='small'
      className={cn(
        'typography-overline gap-2 rounded-lg border border-secondary-100 px-2 py-2 font-bold uppercase text-secondary-100',
        className,
      )}
      {...delegated}
    >
      {t('global.misc.beta.label')}
      <InfoPopover
        popoverTriggerProps={{ className: 'text-secondary-100' }}
        title={t('global.misc.beta.tooltip.title')}
        body={t('global.misc.beta.tooltip.body')}
      />
    </Pill>
  );
};
