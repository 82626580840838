import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import bbox from '@turf/bbox';
import { FeatureCollection, Geometry } from 'geojson';
import { LngLatBoundsLike } from 'mapbox-gl';

import { CellProperties } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

import { useGetJsonModule } from './useGetJsonModule';
import { useProjectKey } from './useProjectKey';

const useGetPolygonModule = () => {
  return useGetJsonModule<FeatureCollection>('polygon');
};

export const useProjectBoundary = (projectIdArg?: string) => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();
  const projectKey = useProjectKey(projectIdArg ?? projectId);

  const getPolygonModule = useGetPolygonModule();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'boundary'],
    queryFn: getPolygonModule,
  });
};

export const useProjectBounds = (projectId?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(projectId);

  const getPolygonModule = useGetPolygonModule();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'boundary', 'bounds'],
    queryFn: () => getPolygonModule().then((polygon) => bbox(polygon) as LngLatBoundsLike),
  });
};

export const useProjectCells = (projectId?: string, year?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(projectId);

  const suffix = year ? `_${year}` : '';
  const getProjectCellsModule = useGetJsonModule<FeatureCollection<Geometry, CellProperties>>(`cells${suffix}`);

  return useQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'cells', year],
    queryFn: async () => {
      const cells = await getProjectCellsModule();
      return {
        ...cells,
        features: cells.features.map((feature, index) => ({
          ...feature,
          id: index + 1,
        })),
      } as FeatureCollection<Geometry, CellProperties>;
    },
  });
};
