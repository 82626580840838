import { useSuspenseQuery } from '@tanstack/react-query';

import { Project } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useGetJsonModule } from './useGetJsonModule';
import { useProjectKey } from './useProjectKey';

export const useProject = (id?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(id);

  const getProjectModule = useGetJsonModule<Project>('project');

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey],
    queryFn: getProjectModule,
  });
};
