import { Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { NCCardSectionHeader, printLatestDate } from '@/components/NCCardV1_2/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { BenchmarkPill } from '@/components/Pill/BenchmarkPill';
import { useBenchmarkForFact, useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { useIsProjectCalculating } from '@/pages/shared/hooks/useIsProjectCalculating';
import { useNCData } from '@/pages/shared/hooks/useNCData';

export const BiodiversityCardStatsTile = () => {
  const { t } = useTranslation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const biodiversityLatestAnalysis = useNCData<number>(R1FactType.r1_biodiversity_latest_analysis);

  const bpBenchmarks = useBenchmarksForProject(R1FactType.biodiversity_buffer_zone_pct_benchmark_graph);
  const bpBenchmarkType = useBenchmarkForFact(bpBenchmarks, biodiversityLatestAnalysis?.value);

  const biodiversity1YrGrowth = useNCData<number>(R1FactType.r1_biodiversity_buffer_zone_pct_previous_1y_uplift_change);

  const isProjectCalculating = useIsProjectCalculating();

  const biodiversityLatestAnalysisLastMeasuredDate = printLatestDate([biodiversityLatestAnalysis?.measured_at]);
  const biodiversityIncreaseSince1yrLastMeasuredDate = printLatestDate([biodiversity1YrGrowth?.measured_at]);

  const lastMeasuredDate = printLatestDate([
    biodiversityLatestAnalysis?.measured_at,
    biodiversity1YrGrowth?.measured_at,
  ]);
  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='biodiversity-stats-tile'>
      <div className='flex flex-col gap-8 lg:gap-4'>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.details.biodiversity.labels.protectedOnFarmHabitat')}
          supplementaryInfo={!isProjectCalculating && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
          popover={{
            title: t('shared.ncaDetail.details.biodiversity.explainers.protectedOnFarmHabitat.title'),
            body: t('shared.ncaDetail.details.biodiversity.explainers.protectedOnFarmHabitat.body'),
          }}
        />
        <div className='flex flex-col gap-8 lg:flex-row lg:justify-between lg:gap-0'>
          <NCFactCapsule
            label={latestAnalysisTranslation}
            capsuleIconProps={{
              icon: getNCChangeIcon(biodiversityLatestAnalysis),
              variant: getNCChangeIconVariant(biodiversityLatestAnalysis),
            }}
            fact={biodiversityLatestAnalysis}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
              body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
                date: biodiversityLatestAnalysisLastMeasuredDate,
              }),
            }}
          />
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            capsuleIconProps={{
              icon: getNCChangeIcon(biodiversity1YrGrowth),
              variant: getNCChangeIconVariant(biodiversity1YrGrowth),
            }}
            fact={biodiversity1YrGrowth}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.biodiversity.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.biodiversity.explainers.oneYearChange.body', {
                date: biodiversityLatestAnalysisLastMeasuredDate,
                previousDate: biodiversityIncreaseSince1yrLastMeasuredDate,
              }),
            }}
          />
        </div>
        {bpBenchmarkType && (
          <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
            <BenchmarkPill benchmarkType={bpBenchmarkType} />
            <span className='typography-h4'>
              {t('shared.ncaDetail.details.biodiversity.explainers.bpBenchmark.title')}
            </span>
            <Trans
              i18nKey={`shared.ncaDetail.details.biodiversity.explainers.bpBenchmark.${bpBenchmarkType}`}
              components={{
                p: <p className='typography-body1 text-text-secondary' />,
              }}
            />
          </Stack>
        )}
      </div>
    </Stack>
  );
};
