import { useTranslation } from 'react-i18next';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from './Explainer';

export const MeasurementExplainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Explainer>
        <ExplainerTrigger data-testid='measurement-explainer' data-cy='measurement-explainer-button'>
          {t('global.measurementExplainer.buttonLabel')}
        </ExplainerTrigger>
        <ExplainerContent>
          <ExplainerTitle>{t('global.measurementExplainer.title')}</ExplainerTitle>
          <ExplainerBody>
            <ExplainerCopy
              title={t('global.measurementExplainer.copy.carbonStorageTitle')}
              body={t('global.measurementExplainer.copy.carbonStorageBody')}
            />
            <ExplainerCopy
              title={t('global.measurementExplainer.copy.waterHoldingTitle')}
              body={t('global.measurementExplainer.copy.waterHoldingBody')}
            />
            <ExplainerCopy
              title={t('global.measurementExplainer.copy.protectedOnFarmHabitatTitle')}
              body={t('global.measurementExplainer.copy.protectedOnFarmHabitatBody')}
            />
          </ExplainerBody>
        </ExplainerContent>
      </Explainer>
    </>
  );
};
