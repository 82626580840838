import { useSuspenseQuery } from '@tanstack/react-query';

import { Observation } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useGetJsonModule } from './useGetJsonModule';
import { useProjectKey } from './useProjectKey';

export const useObservation = (id?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(id);

  const getObservationModule = useGetJsonModule<Observation[]>('observation');

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'observation'],
    queryFn: async () => {
      const observation = await getObservationModule();

      return observation.map((o, index) => ({ ...o, id: o.key || index + 1 }));
    },
  });
};
