import { cn, Divider, Pill, Stack, StackProps } from '@landler/tw-component-library';
import React, { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { useProject } from '../hooks/useProject';

export const ProjectOverview: React.FC = () => {
  const { t } = useTranslation();

  const project = useProject().data;

  return (
    <div
      className='grid-row grid auto-rows-min items-start overflow-hidden rounded-3xl bg-white-100'
      data-testid='conservation-project-overview'
    >
      <DataGroup className='items-start'>
        <DataStack>
          <DataStackLabel>{t('shared.projects.project.conservation.overview.labels.projectName')}</DataStackLabel>
        </DataStack>
        <DataStackContent>{project.name}</DataStackContent>
      </DataGroup>

      <Divider />

      <DataGroup className='grid grid-cols-1 gap-6 md:grid-cols-2'>
        <DataStack>
          <DataStackLabel>{t('shared.projects.project.conservation.overview.labels.management')}</DataStackLabel>
          <DataStackContent>{project.organization.name}</DataStackContent>
        </DataStack>

        <DataStack>
          <DataStackLabel>{t('shared.projects.project.conservation.overview.labels.projectSize')}</DataStackLabel>
          <DataStackContent>{project.area}</DataStackContent>
        </DataStack>

        <DataStack className='md:col-span-2'>
          <DataStackLabel>{t('shared.projects.project.conservation.overview.labels.location')}</DataStackLabel>
          <DataStackContent>
            {project.location_description || t('global.plot.mapPopup.unknownLocation')}
          </DataStackContent>
        </DataStack>
      </DataGroup>

      <Divider />

      <DataGroup className='grid grid-cols-1 gap-6'>
        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.conservation.labels.biomes')}</DataStackLabel>
          <Stack direction='row' spacing={2} className='flex-wrap'>
            {project.biomes.map((biome) => (
              <Pill key={biome} className='bg-warning-light' size='small'>
                {biome}
              </Pill>
            ))}
          </Stack>
        </DataStack>
        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.conservation.labels.ecoregion')}</DataStackLabel>
          <DataStackContent>
            <Ecoregions />
          </DataStackContent>
        </DataStack>
      </DataGroup>
    </div>
  );
};

const Ecoregions = () => {
  const project = useProject().data;
  // eslint-disable-next-line prefer-destructuring
  const ecoregions = project.ecoregions;

  if (ecoregions.length === 1) {
    return <>{ecoregions[0]}</>;
  }

  return (
    <ul className='ml-6 list-disc'>
      {ecoregions.map((ecoregion) => (
        <li key={ecoregion} className='mb-1'>
          {ecoregion}
        </li>
      ))}
    </ul>
  );
};

const DataGroup: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...delegated }) => (
  <div className={cn('min-w-0 p-6', className)} {...delegated} />
);

const DataStack = ({ className, ...delegated }: StackProps) => {
  return <Stack className={cn('min-w-0', className)} {...delegated} />;
};

const DataStackLabel: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-overline mb-2 text-text-secondary', className)} {...delegated} />
);
const DataStackContent: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-h3 min-w-0 break-words', className)} {...delegated} />
);
