/**
 * These utilities are for asserting conditions inside source code. Do not use
 * this to write assertions inside test files..
 */

/**
 * **Do not use for writing tests.**
 *
 * Assert a condition. If condition is not met, an error is thrown with the
 * provided message. It also acts as a type-guard.
 *
 * @example
 * ```ts
 * const user: { email?: string } = {};
 *
 * assert(
 *   typeof user.email === "string" && user.email.length > 0,
 *   `Expected valid email, received ${user.email}`,
 * );
 *
 * const email = user.email; // email is of type string
 * ```
 */
export const assert: (condition: unknown, message: string) => asserts condition = (condition, message) => {
  if (!condition) {
    throw Error(message);
  }
};

/**
 * **Do not use for writing tests.**
 *
 * Assert whether something is not null or undefined, throws an error otherwise.
 * It also acts as a type-guard.
 *
 * @example
 * ```ts
 * const user: { email?: string | null } = {}
 *
 * assertIsDefined(obj.email);
 *
 * const email = user.email // email is of type string
 * ```
 */
export const assertIsDefined: <T>(value: T, name?: string) => asserts value is NonNullable<T> = (val, name) => {
  if (val == null) {
    throw new Error(`Expected ${name ?? 'value'} to be defined, but received ${val}`);
  }
};
