import { useSuspenseQuery } from '@tanstack/react-query';

import { Tileset } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useGetJsonModule } from './useGetJsonModule';
import { useProjectKey } from './useProjectKey';

type TilesetsByLayer = {
  [layer: string]: {
    [year: string]: Tileset;
  };
};

export const useTilesetsByLayer = () => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey();

  const getTilesetsModule = useGetJsonModule<TilesetsByLayer>('tilesets');

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', 'tilesets', projectKey],
    queryFn: getTilesetsModule,
  });
};
