import { useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { Dashboard, Fact, FactType } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useGetJsonModule } from './useGetJsonModule';
import { useProjectKey } from './useProjectKey';

const useDashboardFactsByType = () => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey();

  const getDashboardModule = useGetJsonModule<Dashboard>('dashboard');

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'dashboard'],
    queryFn: async () => {
      const dashboardData = await getDashboardModule();

      return dashboardData.facts.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.name]: curr,
        };
      }, {} as Record<FactType, Fact>);
    },
  });
};

export const useFact = <V, F extends FactType = FactType>(factType: F) => {
  const { data: dashboardFacts } = useDashboardFactsByType();

  return useMemo(() => {
    // eslint-disable-next-line security/detect-object-injection
    return dashboardFacts[factType] as Fact<F, V>;
  }, [dashboardFacts, factType]);
};
