import React from 'react';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';

import { useUserMe } from './useUserMe';

export const useMembershipType = () => {
  const userMe = useUserMe();
  // eslint-disable-next-line prefer-destructuring
  const membership = userMe.data.membership;

  return membership.type;
};

export const useRequireMembershipType = (requiredMembershipType: keyof typeof MembershipWithOrganizationTypeEnum) => {
  const membershipType = useMembershipType();

  return (node: React.ReactNode) => {
    // eslint-disable-next-line security/detect-object-injection
    if (MembershipWithOrganizationTypeEnum[requiredMembershipType] !== membershipType) {
      return null;
    }

    return node;
  };
};
