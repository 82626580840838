import { ProjectType } from '@/api/rest/resources/types/project';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { assert, assertIsDefined } from '@/utils/assert';

const projectKeys = ['majete', 'dabchick', 'odzala'];

export const useProjectKey = (id?: string) => {
  const projectDetail = useProjectDetailById(id).data;

  assert(
    projectDetail.type === ProjectType.conservation,
    `Expected project of type '${ProjectType.conservation}', received '${projectDetail.type}'.`,
  );

  const projectKey = projectKeys.find((key) => projectDetail.name.toLowerCase().includes(key));

  assertIsDefined(projectKey, 'projectKey');

  return projectKey;
};
