import { ButtonLink, RiArrowRightUpLine, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { useMethodologyPageLink } from '@/pages/shared/projects/pages/v1_2/project/components/conservation/hooks/useMethodologyPageLink';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from './Explainer';

export const HabitatIntactnessExplainer = () => {
  const { t } = useTranslation();
  const methodologyPageLink = useMethodologyPageLink();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='habitat-intactness-explainer'>{t('global.howDoWeMeasureThis')}</ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>
          {t('shared.ncaDetail.ecologicalIntegrity.breakdown.habitatIntactness.explainers.howDoWeMeasure.title')}
        </ExplainerTitle>
        <Stack spacing={8} className='items-start pb-6'>
          <ExplainerBody className='pb-0'>
            <ExplainerCopy
              body={
                <div className='columns-1 leading-[22px] text-text-secondary md:columns-2 lg:columns-3'>
                  <Trans
                    i18nKey='shared.ncaDetail.ecologicalIntegrity.breakdown.habitatIntactness.explainers.howDoWeMeasure.body'
                    components={{
                      ol: <ol className='ml-6 list-decimal space-y-2.5' />,
                      li: <li />,
                    }}
                  />
                </div>
              }
            />
          </ExplainerBody>
          <ButtonLink
            to={methodologyPageLink}
            target='_blank'
            variant='text'
            rightAdornment={<RiArrowRightUpLine />}
            className='-left-5'
          >
            {t('shared.learnMoreAboutMethodology')}
          </ButtonLink>
        </Stack>
      </ExplainerContent>
    </Explainer>
  );
};
