import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, Source } from 'react-map-gl';

import { Threat } from '@/api/rest/resources/conservation';

import { useTilesetsByLayer } from '../../../components/conservation/hooks/useTilesets';
import { COLORS } from '../constants';
import { useControlsContext } from '../hooks/useControlsForm';
import { useMoveInteractiveLayersToTop } from '../hooks/useInteractiveLayers';
import { BinaryLegend, IndexLegend, Legend } from './Legend';

export const HabitatThreatLayers = () => {
  const controlsForm = useControlsContext();

  const [threatFilters] = controlsForm.watch(['threatFilters']);

  const showCombined = !threatFilters || threatFilters.length === 0;

  return showCombined ? <CombinedLayer /> : <ThreatLayers />;
};

const COMBINED_LAYER_SOURCE_ID = 'combined-layer-source';
const COMBINED_LAYER_FILL_LAYER_ID = 'combined-layer-fill-layer';

const CombinedLayer = () => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();

  const [year] = controlsForm.watch(['year']);

  const tilesetsByLayer = useTilesetsByLayer().data;

  // eslint-disable-next-line security/detect-object-injection
  const tileset = tilesetsByLayer.combined?.[year];

  useMoveInteractiveLayersToTop(COMBINED_LAYER_SOURCE_ID);

  if (!tileset) {
    return null;
  }

  return (
    <>
      <Source
        key={tileset.id} // Force load tilesets
        id={COMBINED_LAYER_SOURCE_ID}
        type='vector'
        url={`mapbox://${tileset.id}`}
      >
        <Layer
          id={COMBINED_LAYER_FILL_LAYER_ID}
          type='fill'
          source={COMBINED_LAYER_SOURCE_ID}
          source-layer={tileset.sourceLayer}
          paint={{
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', 'value'],
              0.0,
              COLORS.combined.index[0],
              1.0,
              COLORS.combined.index[1],
            ],
            'fill-opacity': 0.4,
          }}
        />
      </Source>
      <Legend>
        <IndexLegend
          // eslint-disable-next-line sonarjs/no-duplicate-string
          title={t('shared.projects.project.conservation.interactiveMap.labels.habitatIntactness')}
          infoPopoverProps={{
            title: t('shared.projects.project.conservation.interactiveMap.labels.habitatIntactness'),
            body: t('shared.projects.project.conservation.interactiveMap.explainers.habitatIntactness'),
          }}
          range={[
            {
              value: 0,
              color: '#F8EDB7',
            },
            {
              value: 1,
              color: '#8B391E',
            },
          ]}
        />
      </Legend>
    </>
  );
};

const ThreatLayers = () => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();

  const [threatFilters] = controlsForm.watch(['threatFilters']);

  if (!threatFilters) {
    return null;
  }

  return (
    <>
      {threatFilters.map((threat) => (
        <ThreatLayer key={threat} threat={threat} />
      ))}

      <Legend>
        <BinaryLegend
          title={t('shared.projects.project.conservation.interactiveMap.labels.habitatIntactness')}
          layers={threatFilters.map((threat) => ({
            label: t(`shared.projects.project.conservation.labels.${threat}`),
            // eslint-disable-next-line security/detect-object-injection, @typescript-eslint/no-explicit-any
            color: (COLORS as any)[threat].binary as string,
          }))}
          infoPopoverProps={{
            title: t('shared.projects.project.conservation.interactiveMap.labels.habitatIntactness'),
            body: t('shared.projects.project.conservation.interactiveMap.explainers.habitatIntactness'),
          }}
        />
      </Legend>
    </>
  );
};

const ThreatLayer = ({ threat }: { threat: Threat }) => {
  useMoveInteractiveLayersToTop(threat);

  const Component = threatLayers[threat as Threat];

  return <Component key={threat} />;
};

const BuildingsLayer = () => {
  return <FillLayer threat='built' />;
};

const CropsLayer = () => {
  return <FillLayer threat='crop' />;
};

const TreeLossLayer = () => {
  return <FillLayer threat='treeloss' />;
};

const MinesLayer = () => {
  return <FillLayer threat='mine' />;
};

const OvergrazingLayer = () => {
  return <FillLayer threat='overgrazing' />;
};

const RoadsLayer = () => {
  const threat = 'road';
  const tileset = useTileset(threat);

  if (!tileset) {
    return null;
  }

  return (
    <Source
      key={tileset.id} // Force load tilesets
      id={threat}
      type='vector'
      url={`mapbox://${tileset.id}`}
    >
      <Layer
        id={`feat-${threat}-fill`}
        type='line'
        source={threat}
        source-layer={tileset.sourceLayer}
        paint={{
          'line-width': 2,
          // eslint-disable-next-line security/detect-object-injection
          'line-color': COLORS[threat].binary,
        }}
      />
    </Source>
  );
};

const threatLayers = {
  built: BuildingsLayer,
  crop: CropsLayer,
  mine: MinesLayer,
  overgrazing: OvergrazingLayer,
  road: RoadsLayer,
  treeloss: TreeLossLayer,
  other: () => null,
} satisfies Record<Threat, FC>;

type FillLayerProps = {
  threat: Threat;
};

const FillLayer = ({ threat }: FillLayerProps) => {
  const tileset = useTileset(threat);

  if (!tileset) {
    return null;
  }

  return (
    <Source
      key={tileset.id} // Force load tilesets
      id={threat}
      type='vector'
      url={`mapbox://${tileset.id}`}
    >
      <Layer
        id={`feat-${threat}-fill`}
        type='fill'
        source={threat}
        source-layer={tileset.sourceLayer}
        paint={{
          'fill-color': COLORS[threat as Threat].binary,
          'fill-opacity': ['case', ['==', ['get', 'value'], 1], 0.8, 0.0],
        }}
      />
    </Source>
  );
};

const useTileset = (threat: Threat) => {
  const controlsForm = useControlsContext();

  const [year] = controlsForm.watch(['year']);

  const tilesetsByLayer = useTilesetsByLayer().data;

  // eslint-disable-next-line security/detect-object-injection
  return tilesetsByLayer[threat]?.[year];
};
