import { Layer, LayerProps, Source } from 'react-map-gl';

import { useProjectCells } from '../../../components/conservation/hooks/useProjectPolygon';
import { COLORS } from '../constants';
import { useControlsContext } from '../hooks/useControlsForm';
import { useMoveInteractiveLayersToTop } from '../hooks/useInteractiveLayers';

const SOURCE_ID = 'ecological-integrity-source';
const FILL_LAYER_ID = 'ecological-integrity-fill-layer';
const OUTLINE_LAYER_ID = 'ecological-integrity-outline-layer';

export const EcologicalIntegrityLayers = () => {
  const year = useControlsContext().watch('year');

  const { data: projectCells } = useProjectCells(undefined, year);

  useMoveInteractiveLayersToTop(SOURCE_ID);

  if (!projectCells) {
    return null;
  }

  return (
    <Source
      key={year} // Force load tilesets
      id={SOURCE_ID}
      type='geojson'
      data={projectCells}
    >
      <Layer {...cellsFillLayer} />
      <Layer {...cellsOutlineLayer} />
    </Source>
  );
};

const cellsOutlineLayer: LayerProps = {
  id: OUTLINE_LAYER_ID,
  type: 'line',
  source: SOURCE_ID,
  paint: {
    'line-width': 1,
    'line-opacity': 0.5,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const cellsFillLayer: LayerProps = {
  id: FILL_LAYER_ID,
  type: 'fill',
  source: SOURCE_ID,
  paint: {
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'ecological_integrity_ts'],
      0.0,
      COLORS.ecological_integrity.index[0],
      1.0,
      COLORS.ecological_integrity.index[1],
    ],
  },
};
