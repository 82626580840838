import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

export const useProjectId = () => {
  const params = useParams<{ projectId: string }>();
  const projectIdContext = useContext(ProjectIdContext);

  const projectId = projectIdContext ?? params.projectId;

  if (!projectId) {
    throw new Error(`Missing projectId, received: ${projectId}`);
  }

  return projectId;
};

export const ProjectIdContext = createContext<string | null | undefined>(null);
