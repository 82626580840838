import { useProjectId } from '@/pages/shared/hooks/useProjectId';

import { useProjectKey } from './useProjectKey';

export const useGetJsonModule = <T = unknown>(path: string) => {
  const projectId = useProjectId();
  const projectKey = useProjectKey(projectId);

  return async () => {
    const module = await import(`../projectData/${projectKey}/${path.replace(/^\/+/, '')}.json`);

    return module.default as T;
  };
};
