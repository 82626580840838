import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { Area, Chart, ComposedChart, getXAxisProps, TooltipProps } from './components/Chart';
import { ChartLegendBar } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { ChartLegendBarItem } from './components/types';
import { RenderConfidenceInterval } from './ConfidenceInterval';
import { NoChartData } from './NoChartData';
import { ChartDataItem, extrapolateReferenceLinesChartData, RenderReferenceLine } from './ReferenceLines';
import { ChartProps, FactValue } from './types';
import { getChartDataFromGraphFact, getXAxisDomainForHistoricGraph, minMaxAxisDomain, sortChartData } from './utils';

const chartColors = {
  whc: { stroke: '#43AFE2', fill: '#6ACDE1' },
  sm: { stroke: '#34A4E2' },
} as const;

const totalFactNames = {
  soil_moisture: 'sm',
  water_holding_capacity: 'whc',
  below_wilting_point: 'wp',
};

const perHaFactNames = {
  soil_moisture_per_ha: 'sm',
  water_holding_capacity_per_ha: 'whc',
  below_wilting_point_per_ha: 'wp',
};

const intrapolateNullValues = (data: ChartDataItem[]) => {
  const whcYearlyValues: Record<string, FactValue | null> = {};
  const wpYearlyValues: Record<string, FactValue | null> = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  Object.entries(data).forEach(([_, currentData]) => {
    whcYearlyValues[new Date(currentData.label).getFullYear().toString()] = currentData.whc ?? null;
    wpYearlyValues[new Date(currentData.label).getFullYear().toString()] = currentData.wp ?? null;
  });
  Object.entries(data).forEach(([date, currentData]) => {
    /**
     * Science spec -
     * whc is produced as 1 value per year, constant throughout the year, sm can have multiple values in a year
     * we use this whc value to fill in whc for all sm datapoints of that year that do not have a coninciding whc datapoint
     * so that the tooltip does not show "No data" for whc since whc is null for those dates
     * but as shown in the graph the step chart for whc would indicate a corresponding whc value
     */
    const defaultWhcValue = currentData.whc ?? whcYearlyValues[new Date(currentData.label).getFullYear().toString()];
    const defaultWpValue = currentData.wp ?? wpYearlyValues[new Date(currentData.label).getFullYear().toString()];

    // eslint-disable-next-line no-param-reassign
    data[Number(date)] = {
      ...data[Number(date)],
      whc: defaultWhcValue ?? null,
      wp: defaultWpValue ?? null,
    };
  });
  return data;
};

export const WaterHistoricalChart: FC<HTMLAttributes<HTMLDivElement> & ChartProps> = ({
  analysisType,
  data,
  className,
  showTooltip,
  ...delegated
}) => {
  const { t } = useTranslation();
  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);

  const factNames = analysisType === 'total' ? totalFactNames : perHaFactNames;
  const dateDataMap = getChartDataFromGraphFact(data, factNames);
  const sortedChartData = sortChartData(dateDataMap, (date) => new Date(date).getTime());
  const intrapolatedData = intrapolateNullValues(sortedChartData);

  const firstTick = intrapolatedData.at(0);
  const lastTick = intrapolatedData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  const xTicksDomain = getXAxisDomainForHistoricGraph(firstTick.label, lastTick.label);
  const xAxisProps = getXAxisProps(xTicksDomain);

  const chartData = extrapolateReferenceLinesChartData(xTicksDomain, intrapolatedData, 'wp');

  return (
    <>
      <Chart.Container
        {...delegated}
        data={chartData}
        config={{
          whc: {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            label: t('global.analysis.waterHoldingCapacity'),
            color: chartColors.whc.stroke,
          },
          sm: {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            label: t('global.analysis.soilMoisture'),
            color: chartColors.sm.stroke,
          },
        }}
      >
        <ComposedChart data={chartData}>
          <defs>
            <linearGradient id='whc' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.whc.fill} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.whc.fill} stopOpacity={0} />
            </linearGradient>
          </defs>
          {RenderConfidenceInterval({
            dataKey: 'whc.confidence_interval',
            type: 'monotone',
          })}
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='sm.value'
            stroke='var(--color-sm)'
            strokeWidth={1}
            fill='none'
            activeDot={ChartTooltipDot}
            connectNulls
          />
          <Area
            type='stepBefore'
            isAnimationActive={false}
            stroke='var(--color-whc)'
            strokeWidth={2}
            fill='url(#whc)'
            dataKey='whc.value'
            connectNulls
            activeDot={ChartTooltipDot}
          />
          {RenderReferenceLine({
            dataKey: 'wp.value',
            type: 'stepBefore',
            label: chartData.some((point) => !!point.wp) ? t('global.analysis.wiltingPoint') : null,
            chartData,
          })}
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={minMaxAxisDomain(0, 1.2)}>
            <Chart.Label>{unit}</Chart.Label>
          </Chart.YAxis>
          {showTooltip && <Chart.Tooltip content={(props) => <CustomTooltip {...props} unit={unit as UnitEnum} />} />}
        </ComposedChart>
      </Chart.Container>
      <CustomLegendBar />
    </>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.waterHoldingCapacity'),
      color: chartColors.whc.fill,
    },
    {
      label: t('global.analysis.soilMoisture'),
      color: chartColors.sm.stroke,
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='mt-8 sm:ml-[28px]' data-testid='water-historical-chart-legend' />
  );
};

const CustomTooltip = ({ active, payload, label, unit }: TooltipProps<ValueType, NameType> & { unit: UnitEnum }) => {
  const { t } = useTranslation();

  /** sm/whc values are not stored in any particular order in the payload array when multiple graphs are rendered,
   *  the payload just carries non-null values, null values are omitted.
   *  If either whc/sm are null, they will not show up in the payload array
   *  Hence we search for the object for a dataKey match, instead of indexing the array
   *  if they exist, we extract the y value, if not, we assume its null
   */
  const smValue = payload?.find((data) => data.dataKey === 'sm.value');
  const whcValue = payload?.find((data) => data.dataKey === 'whc.value');
  const smDisplay =
    smValue?.value != null
      ? `${getDisplayNumber(smValue?.value as string, window.navigator.language)} ${unit}`
      : t('global.analysis.noData');
  const whcDisplay =
    whcValue?.value != null
      ? `${getDisplayNumber(whcValue?.value as string, window.navigator.language)} ${unit}`
      : t('global.analysis.noData');

  if (!active) return null;

  return (
    <ChartTooltip>
      <ChartTooltipTextPrimary>{`${t('global.analysis.soilMoisture')}: ${smDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextPrimary>{`${t('global.analysis.waterHoldingCapacity')}: ${whcDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printMonthYear(label)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
