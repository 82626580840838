import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ChartLegendBarItem } from '../components/types';
import { SHARED_CHART_COLORS } from '../constants';

export const useLegendItems = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        label: t('shared.ncaDetail.details.explainers.confidenceInterval.title'),
        color: SHARED_CHART_COLORS.confidenceIntervals.fill,
        shape: (
          <span
            className='h-[7px] w-[14px]'
            style={{ backgroundColor: SHARED_CHART_COLORS.confidenceIntervals.fill }}
          />
        ),
        popoverContent: {
          title: t('shared.ncaDetail.details.explainers.confidenceInterval.title'),
          body: t('shared.ncaDetail.details.explainers.confidenceInterval.body'),
        },
      },
    ] as ChartLegendBarItem[];
  }, [t]);
};
